import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);
import projects from "./modules/projects.js";
import documents from "./modules/documents.js";
import home from "./modules/home.js";
import persons from "./modules/persons.js";
import news from "./modules/news.js";
import press from "./modules/press.js";
import activity from "./modules/activity.js";
import sub_departments from "./modules/sub_departments.js";

export default function createStore(env) {
  return new Vuex.Store({
    modules: {
      projects,
      documents,
      home,
      persons,
      news,
      press,
      activity,
      sub_departments,
    },
    state() {
      return {
        _env: env,
        _modals: [],
        user: null,
        showMobileMenu: false,
        department: null,
        categories: [],
        document_types: [],
        government_news_types: [],
        activities_department: [],
        activities_contacts: [],
        activities_project: [],
        activities_press: [],
      };
    },
    actions: {
      save({ state }, data) {
        if (data) {
          Object.keys(data).forEach((key) => {
            state[key] = data[key];
          });
        }
      },
    },
  });
}
